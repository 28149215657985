<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Mails via mailsysteem
    </h1>

    <TableMails v-bind="filters" />
  </div>
</template>

<script setup>
import { ref } from 'vue'

import TableMails from '@/components/Table/Mails/Index.vue'

const filters = ref({
  subject: null,
  rit_id: null,
  klant_id: null,
  facturatie_id: null,
  user_id: null,
})
</script>
